import React from "react";
import Image from "next/legacy/image";
import styled from "@emotion/styled";

import { Section } from "../Section";

interface Logo {
  url: string;
  alt: string;
}

export type LogoDisplaySectionProps = {
  title: string;
  logos: Logo[];
};

const Logos = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  @media ${(props) => props.theme.bp.tabletUp} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
`;

const ImageWrapper = styled.div`
  align-items: center;
  background: ${(props) => props.theme.colors.mist};
  display: flex;
  justify-content: center;
  padding: 10px;

  // Show max 4 on mobile
  &:nth-of-type(5) {
    display: none;
  }

  @media ${(props) => props.theme.bp.tabletUp} {
    &:nth-of-type(5) {
      display: unset;
    }
  }

  @media ${(props) => props.theme.bp.desktopUp} {
    padding: 20px;
  }
`;

export const LogoDisplaySection = ({
  title,
  logos,
}: LogoDisplaySectionProps) => {
  return (
    <Section isLarge>
      <Section.Heading title={title} />
      <Logos>
        {logos.slice(0, 5).map((logo, idx) => (
          <ImageWrapper key={idx}>
            <Image
              alt={logo.alt}
              height={50}
              objectFit="contain"
              src={logo.url}
              width={150}
            />
          </ImageWrapper>
        ))}
      </Logos>
    </Section>
  );
};
